* {
  margin: 0;
  padding: 0;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
body {
  margin: 0 !important;
}

:root {
  /* Responsive Breakpoints */
  --SMALL: 0px;
  --MEDIUM: 735px;
  --LARGE: 1069px;
  --X-LARGE: 1440px;
  /* Colors */
  --background: rgb(241, 245, 246);
  --background-dark: #1a0e2f;
  --footer-background: #101011;
  --shadow: rgb(26, 14, 47, 0.05);
  --neutral-100: rgb(251, 250, 252);
  --neutral-200: rgba(244, 240, 247, 1);
  --neutral-300: rgb(231, 228, 235);
  --neutral-400: rgba(211, 208, 214, 1);
  --neutral-500: rgba(182, 179, 186, 1);
  --neutral-600: rgb(135, 132, 138);
  --neutral-700: rgb(112, 109, 115);
  --neutral-800: rgba(49, 47, 51, 1);
  --neutral-900: rgba(31, 9, 55, 1);
  --purple-primary: rgb(151, 71, 255);
  --purple-light: #f2e5ff;
  --purple-dark: #400084;
  --purple-primary-light: rgba(242, 229, 255, 1);
  --purple-primary-dark: rgba(64, 0, 132, 1);
  --primary-accent-blue: rgba(48, 79, 254, 1);
  --bg-gradient: linear-gradient(
    77deg,
    var(--purple-primary-light) -5.19%,
    var(--primary-accent-blue) 101.98%
  );
  --white-primary: rgb(255, 255, 255);
  --black-primary: rgb(0, 0, 0);
  --opaque-black: rgba(0, 0, 0, 0.8);
  --text-body: #1a0e2f;
  --text-header: #1a0e2f;
  --text-button-default: #1a0e2f;
  --text-button-disabled-bg: var(--neutral-700);
  --text-button-disabled-text: var(--neutral-100);
  --text-button-hover: var(--purple-primary);
  --text-button-pressed: var(--purple-primary);
  /* Button Colors */
  --light-button: linear-gradient(273deg, #e2e0fd -34.32%, #a8a4f8 126.05%);

  /* numbers */
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-60: 60px;
  --spacing-64: 64px;

  --spacing-header-small: 78px;
  --spacing-header-big: 102px;
  --spacing-section-small: 32px;
  --spacing-section-medium: 80px;
  --spacing-section-large: 120px;
  --spacing-section-xl: 144px;

  --side-margin-small: 16px;
  --side-margin-medium: 48px;
  --side-margin-large: 60.5px;
  --side-margin-xl: 120px;

  /* Container Size */
  --container-width-small: 100%;
  --container-width-medium: 639px;
  --container-width-large: 948px;
  --container-width-xl: 1200px;

  /* TYPOGRAPHY */
  --letter-spacing: -0.5px;
  --anton-font-weight: 400;

  /* button small Typography */
  --font-weight-body-small: 400;
  --line-height-body-small: 24px;
  --font-size-body-small: 16px;

  /* body regular typography */
  --font-weight-body-regular: 400;
  --line-height-body-regular: 27px;
  --font-size-body-regular: 18px;

  /* body large typography */
  --font-weight-body-large: 400;
  --line-height-body-large: 36px;
  --font-size-body-large: 24px;

  /* h1 Typography */
  --font-weight-h1: 400;
  --line-height-h1: 100px;
  --font-size-h1: 75px;

  /* h2 Typography */
  --font-weight-h2: 600;
  --line-height-h2: 67.2px;
  --font-size-h2: 56px;

  /* h3 Typography */
  --font-weight-h3: 600;
  --line-height-h3: 50.4px;
  --font-size-h3: 42px;

  /* h4 Typography */
  --font-weight-h4: 600;
  --line-height-h4: 48px;
  --font-size-h4: 32px;

  /* h5 Typography */
  --font-weight-h5: 600;
  --line-height-h5: 36px;
  --font-size-h5: 24px;

  /* h6 Typography */
  --font-weight-h6: 600;
  --line-height-h6: 21.6x;
  --font-size-h6: 18px;

  /* button Regular Typography */
  --font-weight-button-regular: 600;
  --line-height-button-regular: 21.78px;
  --font-size-button-regular: 18px;

  /* button Large Typography */
  --font-weight-button-large: 600;
  --line-height-button-large: 29px;
  --font-size-button-large: 24px;

  /* body large typography */
  --font-weight-page-title-small: 400;
  --line-height-page-title-small: 56px;
  --font-size-page-title-small: 42px;

  /* Background gradient size */
  --background-gradient-size-large: 1138px;
  --background-gradient-size-medium: 630px;
  --background-gradient-size-small: 230px;

  --background-gradient: radial-gradient(
    50% 50% at 50% 50%,
    rgba(170, 189, 255, 0.4) 0%,
    rgba(170, 178, 255, 0) 100%
  );

  --background-gradient-dark: radial-gradient(
    50% 50% at 50% 50%,
    rgba(90, 0, 248, 0.3) 0%,
    rgba(15, 0, 251, 0) 100%
  );

  --homepage-min-height: 700px;
  --homepage-min-width: 393px;
}

.fullHeight {
  height: 100vh !important;
  overflow-x: hidden;
}
