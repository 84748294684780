/* TYPOGRAPHY */
/* vars defined in ./globals.css */

.bodyLarge,
.bodyRegular,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.li {
  letter-spacing: var(--letter-spacing);
}

.lightPage .bodyLarge,
.lightPage .bodyRegular,
.lightPage .h1,
.lightPage .h2,
.lightPage .h3,
.lightPage .h4,
.lightPage .h5,
.lightPage .h6,
.lightPage .li,
.lightPage .text {
  color: var(--text-body);
  border-color: var(--text-button-default);
}

.darkPage .bodyLarge,
.darkPage .bodyRegular,
.darkPage .h1,
.darkPage .h2,
.darkPage .h3,
.darkPage .h4,
.darkPage .h5,
.darkPage .h6,
.darkPage .li,
.darkPage .text {
  color: var(--white-primary);
  border-color: var(--white-primary);
}

.h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  line-height: var(--line-height-h1);
}

.h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  line-height: var(--line-height-h2);
}

.h3.insights,
.h4 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-h5);
  line-height: var(--line-height-h5);
  white-space: pre-wrap;
}
.h3.insights {
  @media (min-width: 735px) {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    line-height: var(--line-height-h3);
  }
}

.h3:not(.insights),
.h4 {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-h4);
  line-height: var(--line-height-h4);
  white-space: pre-wrap;
}
.h3 {
  @media (min-width: 735px) {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    line-height: var(--line-height-h3);
  }
}

.h5 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-h5);
  line-height: var(--line-height-h5);
}

.h6 {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-h6);
  line-height: var(--line-height-h6);
}

.bodyRegular,
.bodyRegular p,
.bodyRegular li,
.bodyLarge,
.bodyLarge p,
.bodyLarge li {
  font-size: var(--font-size-body-regular);
  font-weight: var(--font-weight-body-regular);
  line-height: var(--line-height-body-regular);
  white-space: pre-wrap;
}

@media (min-width: 735px) {
  .bodyLarge,
  .bodyLarge p,
  .bodyLarge li {
    font-size: var(--font-size-body-large);
    font-weight: var(--font-weight-body-large);
    line-height: var(--line-height-body-large);
  }
}
